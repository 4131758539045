import React, { useState } from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import Arrow from '../../../components/Arrow-Left.png';
import HomeLogin from '../../../components/StudentsSecond.png';

import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ReactFlagsSelect from 'react-flags-select';

import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { useSendPhoneOtpMutation } from 'store/apis/otp';
import { NotificationManager } from 'react-notifications';

export default function PhoneNumberValidation({ setStep }) {
  const [selected, setSelected] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isInvalidError, setIsInvalidError] = useState(false);

  const [sendPhoneOtp, sendPhoneOtpRes] = useSendPhoneOtpMutation();

  const handleSendPhoneOtp = async () => {
    setIsInvalidError(false)
    try {
      if (!isValidPhoneNumber(phoneNumber)) {
        setIsInvalidError(true)
        NotificationManager.error('Please enter a valid phone number');
        return;
      }
  
      const phonNum = parsePhoneNumber(phoneNumber);
  
      const res = await sendPhoneOtp({
        countryCode: `+${phonNum.countryCallingCode}`,
        phoneNumber: phonNum.nationalNumber?.length == 9 ? `0${phonNum.nationalNumber}` : phonNum.nationalNumber,
      }).unwrap();
  
      setStep((prevStep) => ({
        ...prevStep,
        current: 'phoneOtp',
        phoneNumber: phoneNumber,
        verificationSid: res.data.verificationSid,
      }));
    } catch (error) {
      if (error?.status === 500) {
        NotificationManager.error('Something went wrong.');
      } else {
        NotificationManager.error('Something went wrong.');
      }
    }
  };

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'birth',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="img-discription" />
        </Box>
      </Stack>
      <Stack className={`${sharedStyles.loginContainer}`}>
      <Box className={sharedStyles.logoWrapper}>
          <Box className={sharedStyles.arrow}>
            <img
              src={Arrow}
              alt="img-discription"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'birth',
                }));
              }}
            />
          </Box>
          <Box className={sharedStyles.logo}>
            <img src={Logo} alt={'logo'} />
          </Box>
        </Box>

        <Stack className="w-10/12 space-y-5 justify-center items-center">
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            style={{ color: '#131515' }}
          >
            Verify Your Phone Number
          </Typography>
          <Typography className="!font-normal !text-xl mt-4 w-[96%] mx-auto">
            To ensure the security of your account, we wil send you a 6 digit code One Time Password. Please provide your phone number to verify your identity and access all the features of G3MS.
          </Typography>
        </Stack>
        <Box
          className="w-4/5 sm:w-3/5"
          sx={{
            '& .MuiOutlinedInput-input': {
              paddingTop: '25px',
              fontSize: '18px',
              fontWeight: 500,
            },
          }}
        >
          <label className="w-full block font-medium text-lg text-left color-[#484848] mb-3">Phone Number</label>
          <div 
          className="flex flex-row gap-3 w-full"
          >
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code) => setSelected(code)}
            searchable
            showSelectedLabel={false}
            searchPlaceholder="(000) 000 000"
            className={`${isInvalidError && 'phone-invalid--wrapper'} [&>button]:h-16 lg:[&>button]:h-14 react-flag--select`}
          />

          <div className={`${isInvalidError && 'phone-invalid--wrapper'} react-phone--input w-full`}>
            <PhoneInput
              country={selected}
              value={phoneNumber || ''}
              onChange={setPhoneNumber}
              placeholder="(000) 000 000"
              className="w-full h-16 lg:h-14 p-4 border border-gray-300 rounded-lg focus:outline-none"
            />
            {isInvalidError && <span className='phone-invalid-message'>Phone number is invalid</span>}
          </div>
          </div>
        </Box>
        <Button
          variant={'contained'}
          size="large"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={handleSendPhoneOtp}
          disabled={
            !phoneNumber ||
            !isValidPhoneNumber(phoneNumber) ||
            sendPhoneOtpRes.isLoading
          }
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}
