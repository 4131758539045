import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Close from '../../../asset/payment/x-close.svg';
import Character from '../../../asset/payment/character.svg';

const Index = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.modalOuterShadow}>
      <div className={styles.convinceToUpgradeContainer}>
        <header className={styles.convinceToUpgradeHead}>
            <img src={Character} alt="" />

            {/* close icon  */}
            <img className={styles.close} src={Close} onClick={() => navigate('/parent/upgrade_plan')} loading="lazy" alt="" />
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>Thank you for completing your profile, John Smith! You’re one step closer to helping your kid unlock their learning potential.</h3>
          <h3 className={styles.heading}>🎉 You’ve Earned a total of $50 G3MS Tokens! 🎉</h3>
          <p className={styles.bodySmTxt}>Thank you for completing your profile! We’ve added $40 Tokens to your wallet. Use these tokens to reward your child with gift cards, avatar upgrades, and more!</p>
          <h3 className={styles.heading}>✨ Turn Tokens into Tangible Rewards with Premium ✨</h3>
          <p className={styles.bodyTxt}>G3MS Premium turns your child’s learning achievements into meaningful rewards while providing tools for academic and career success.</p>
          <ul>
           <li>🎁 Redeem tokens for premium gift cards and exclusive rewards.</li>
           <li>✏️ Get homework help with our AI Tutor for math, essay writing, and coding.</li>
           <li>📚 Unlock a full library of state-aligned curriculum in ELA, Science, and Social Studies.</li>
           <li>🚀 Prepare your child for the future with workforce skills and coding challenges.</li>
           <li>🎓 Access test prep for high school entrance exams (ISEE, SHSAT, SSAT, TACHS) and the SAT/ACT.</li>
          </ul>
          <div>
            <h3 className={styles.heading}>Start Your Free 7-Day Trial Today!</h3>
            <h3 className={styles.heading} style={{marginBottom: '0px'}}>Unlock the full G3MS experience with instant access to all Premium features. Includes a 7-day free trial!</h3>
          </div>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button onClick={() => navigate('/parent/upgrade_plan')}>Try G3MS for Free Today</button>
        </footer>
      </div>
    </div>
  );
}

export default Index;  