import { data } from './data';
import PaymentCard from './card';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import ReferralOrPromo from './ReferralOrPromo';
import PaymentConfirmation from './paymentConfirmation';
import React, { useEffect, useState } from 'react';
import Modal from '../../auth/components/modal/modal';

import Logo from '../../asset/logo.svg'

const Index = () => {
    const navigate = useNavigate();
    const [modalReferralOrPromoOpen, setModalReferralOrPromoOpen] = useState(false);
    const [modalPaymentConfirmationOpen, setPaymentConfirmationOpen] = useState(false);

    return (
        <>
            <div className={styles.pagePadding}>
                <div className={styles.upgradePlanContainer}>
                    <header>
                        <img src={Logo} alt="" />
                        <h2>Hey James! Let’s select the best G3MS plan for you.</h2>
                    </header>

                    <div className={styles.plansShadow}>
                        <main>
                            {data?.map((item, _index) => (
                                <PaymentCard key={_index} data={item} setOpen={setPaymentConfirmationOpen} />
                            ))} 
                        </main>

                        <footer>
                            <button onClick={() => setPaymentConfirmationOpen(true)}>Keep the FREE plan</button>
                            <span onClick={() => setModalReferralOrPromoOpen(true)}>Got a Promo or Referral Code?</span>
                        </footer>
                    </div>
                </div>
            </div>

            <Modal open={modalReferralOrPromoOpen} setOpen={setModalReferralOrPromoOpen} overlayStyles={styles.modalOverlay} contentStyles={styles.modalContent}>
                <ReferralOrPromo setOpen={setModalReferralOrPromoOpen} />
            </Modal>

            <Modal open={modalPaymentConfirmationOpen} setOpen={setPaymentConfirmationOpen} overlayStyles={styles.modalOverlay} contentStyles={styles.modalContent}>
                <PaymentConfirmation setOpen={setPaymentConfirmationOpen} />
            </Modal>
        </>
    );
};

export default Index;