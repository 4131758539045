import { isEmail, isPhone } from '@core/utils/validators';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { setParentEmail, setParentPhone } from 'store/Slice/registerSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import analytics from 'utils/analytics';
import Logo from '../../../../asset/logo.svg';

export default function ParentsDialog({ open, onClose, setStep }) {
  const dispatch = useAppDispatch();
  const { parentEmail, parentPhone } = useAppSelector(
    (state) => state.registerReducer,
  );

  function onNext() {
    // check if email is valid regex
    if (!isPhone(parentPhone)) {
      NotificationManager.error('Please enter a valid phone number', 'Error');
      return;
    }
    if (!isEmail(parentEmail)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }
    
    analytics.logEvent('parent_email_phone', {
      parentEmail,
      parentPhone,
    });

    setStep((prevStep) => ({
      ...prevStep,
      current: 'signup',
    }));
  }
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <img className='h-16 mt-8 mx-auto mb-5' src={Logo} alt="" />
      <DialogTitle
        className="!font-semibold !text-3xl sm:!text-3xl md:!text-2xl lg:!text-3xl"
        sx={{ color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
      >
        Ask a Grownup?
      </DialogTitle>

      <span onClick={onClose} style={{
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '16px',
        height: '16px',
        cursor: 'pointer',
        background: '#f3f3f3',
        borderRadius: '100px',
        padding: '6px',
        boxSizing: 'content-box',
        border: '1px solid #eaeaea',
        boxShadow: '0px 11px 15px -7px rgba(76, 78, 100, 0.2),0px 24px 38px 3px rgba(76, 78, 100, 0.14),0px 9px 46px 8px rgba(76, 78, 100, 0.12)'
      }}>
        <img src="https://img.icons8.com/?size=100&id=79023&format=png&color=000000" alt="close" />
      </span>
      <DialogContent>
        <Box
          textAlign={'center'}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': { fontSize: '18px', fontWeight: 500 },
          }}
        >

      <Typography
            className="!text-xl sm:!text-md md:!text-lg"
            sx={{
              mx: 6,
              color: '#000',
              lineHeight: 1,
            }}
          >
            You can still join us, but before you start using the camera or
            claiming your rewards, we'll need a grownup's okay.
          </Typography>
          <FormControl
            variant="outlined"
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-input': { paddingTop: '50px' },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              className="!font-semibold !text-2xl sm:!text-base md:!text-lg"
              style={{
                marginTop: '20px',
                color: '#130F26',
              }}
            >
              What is your parent's email?
            </InputLabel>
            <TextField
              placeholder="Type your parents email"
              id="my-textfield"
              variant="outlined"
              style={{
                paddingTop: '30px !important',
                fontSize: '18px',
                fontWeight: 500,
              }}
              sx={{ paddingTop: '30px !important' }}
              onChange={(e) => dispatch(setParentEmail(e.target.value))}
              value={parentEmail || ''}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-input': { paddingTop: '50px' },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              className="!font-semibold !text-2xl sm:!text-base md:!text-lg"
              style={{
                marginTop: '20px',
                color: '#130F26',
              }}
            >
              What is your parent’s phone number?
            </InputLabel>
            <TextField
              placeholder="(000) 000 000"
              id="my-textfield"
              variant="outlined"
              style={{
                paddingTop: '30px !important',
                fontSize: '18px',
                fontWeight: 500,
              }}
              sx={{ paddingTop: '30px !important' }}
              onChange={(e) => dispatch(setParentPhone(e.target.value))}
              value={parentPhone || ''}
            />
          </FormControl>
          <Box
            mt={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              size="large"
              sx={{
                mr: 2,
                height: '46px',
                width: '90%',
                minWidth: '120px',
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              variant="contained"
              onClick={onNext}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
