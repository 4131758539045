
export const data = [
    {
        title: 'Monthly Plan',
        amount: '7.99',
        additionalAmount: '5.00',
        amountDescription: 'Per month for 1 user',
        additionalAmountDescription: 'Per Month',
    },
    {
        active: true,
        title: 'Yearly Plan',
        amount: '76.80',
        additionalAmount: '48.00',
        amountDescription: 'Per Year for 1 user',
        amountDescription02: '20% OFF',
        additionalAmountDescription: 'Per Month',
    },
    {
        title: 'Lifetime Plan',
        amount: '169',
        amountDescription: 'Unlimited for 1 user',
    }
]