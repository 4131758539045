import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import QrCode from '../../../asset/payment/qr.svg';
import Close from '../../../asset/payment/x-close.svg';

const Index = ({ setOpen }) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(true);

    return (
                <div className={styles.promoReferralContainer}>
                    <header>
                        <h2>Promo or Referral Code</h2>
                        <img className={styles.close} src={Close} onClick={() => setOpen(false)} loading="lazy" alt="" />
                    </header>

                    <main>
                        <div className={styles.codeField}>
                            <label htmlFor="">Enter code</label>
                            <input type="text" placeholder='Enter your code...' />
                        </div>

                        <span className={styles.Or}>Or</span>

                        <div className={styles.scanQr}>
                            <img src={QrCode} alt="" />
                            <h4>Scan QR Code</h4>
                        </div>
                    </main>

                    <button onClick={() => setOpen(false)}>Submit</button>
                </div>
    );
};

export default Index;