import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Download from '../../../asset/payment/download.svg';
import Close from '../../../asset/payment/x-close.svg';
import ConfirmationIcon from '../../../asset/payment/success.svg';

const Index = ({ setOpen }) => {
    const navigate = useNavigate();

    const data = [
        {title: 'References Number', value: '000085752257'},
        {title: 'Date', value: 'Mar 22, 2023'},
        {title: 'Time', value: '07:80 AM'},
        {title: 'Payment Method', value: 'Credit Card'}
    ]

    return (
                <div className={styles.paymentConfirmationContainer}>
                    <img className={styles.confirmationIcon} src={ConfirmationIcon} alt="" />

                    <header>
                        <h2>Payment Confirmed!</h2>
                        {/* <img className={styles.close} src={Close} onClick={() => setOpen(false)} loading="lazy" alt="" /> */}
                    </header>

                    <main>
                        <span className={styles.divider} />

                        {data?.map((item, _index) => (
                        <div className={styles.recieptEntry}>
                        <h4>{item?.title}</h4>
                        <span>{item?.value}</span>
                    </div>
                        ))}

                        <span className={styles.divider} />

                        <div className={styles.recieptEntry}>
                            <h4>Amount</h4>
                            <span>$ 7.99</span>
                        </div>
                    </main>
                    <span className={styles.divider} />

                    <button onClick={() => setOpen(false)}>
                        <img src={Download} alt="" />
                        Get PDF Receipt
                    </button>
                </div>
    );
};

export default Index;