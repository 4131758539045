import styles from './styles.module.scss';

const Index = ( { data, setOpen } ) => {
    return (
        <div>
            {data?.active && <button className={styles.recommendedBtn}>Recommended</button>}
            <div className={`${styles.paymentCard} ${data?.active && styles.active} }`}>
                <h3 style={{marginTop: data?.active && '12px'}}>{data?.title}</h3>
                <div className={styles.monthly}>
                    <h2>${data?.amount}</h2>
                    <div className={styles.monthlyOffer}>
                        <span style={{marginBottom: data?.additionalAmountDescription && '0px'}}>{data?.amountDescription}</span>
                        <span>{data?.amountDescription02}</span>
                    </div>
                </div>
                <span className={styles.additionalUser}>Additional User</span>
                {data?.additionalAmount && <div className={styles.additionalMonthly}>
                    <h2>${data?.additionalAmount}</h2>
                    <span>{data?.additionalAmountDescription}</span>
                </div>}
                <h3 style={{fontWeight: '500'}}>FEATURES</h3>
                <ul>
                    <li>🎁 Redeem tokens for gift cards. </li>
                    <li>✏️ AI Tutor for math, writing, and coding. </li>
                    <li>📚 Full state-aligned curriculum. </li>
                    <li>🚀 Workforce skills and test prep.</li>
                </ul>
                <button onClick={() => setOpen(true)}>Subscribe now</button>
            </div>
        </div>
    )
}

export default Index;